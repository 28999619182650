<template>
  <div v-loading.fullscreen.lock="loading"></div>
</template>
<script>
import { mapActions } from "vuex";
export default {
  name: "callback",
  data() {
    return {
      loading: true
    };
  },
  methods: {
    ...mapActions("oidc", ["oidcSignInCallback"])
  },
  mounted() {
    this.oidcSignInCallback()
      .then(path => {
        // debugger;
        this.$router.push("/");
      })
      .catch(e => {
        // debugger;
        this.$router.push("/");
      });
  }
};
</script>
